import { graphql, useStaticQuery } from "gatsby"
import getMainPath from "../Common/MainPaths"
import { Link } from "gatsby-plugin-react-i18next"
import { Heading1 } from "../Styling/Headings"
import { Ingress } from "../Styling/Body"
import { Image, Transformation } from "cloudinary-react"

const ProducerLine = ({ heading = "", ingress = "", mainPath = "", slug = "", className = "" }) => {
  return (
    <div className={className}>
      <Link to={"/nb" + mainPath + "/" + slug}>
        <div>
          <Heading1>
            <span className="hover:text-tomat-500">{heading}</span>
          </Heading1>
          <Ingress>
            <span className="flex text-left -mt-8 text-body16">{ingress}</span>
          </Ingress>
        </div>
      </Link>
    </div>
  )
}

const ProducerSection = () => {
  // const mainPath = getMainPath("productgroup")
  const producerPath = getMainPath("farmer")

  // Hent alle produktgrupper fra Contentful
  const data = useStaticQuery(graphql`
    {
      allContentfulFarmer(filter: { node_locale: { eq: "nb-NO" } }) {
        nodes {
          title
          slug
          ingress {
            ingress
          }
          node_locale
          contentful_id
          subtitle
          heroImage {
            id
            public_id
            height
            width
            format
            url
            secure_url
            context {
              custom {
                Credits
                alt
                caption
              }
            }
          }
        }
      }
    }
  `)
  let counter = 0
  const dataProducerGroups = data.allContentfulFarmer.nodes.map((item) => ({
    index: counter++,
    slug: item.slug,
    title: item.title || "Tittel mangler i contentful",
    text: item.ingress.ingress || "Text mangler i Contentful",
  }))

  counter = 0
  const images = data.allContentfulFarmer.nodes
    .filter((item) => {
      // remove entries with no images
      return item.heroImage[0]?.public_id !== undefined
    })
    .map((item) => ({
      image: item.heroImage || [],
    }))

  return (
    <div>
      <div className="flex justify-center mt-24">
        <Heading1>Noen av våre produsenter</Heading1>
      </div>
      <div className="lg:grid lg:grid-cols-2 lg:grid-rows-2 lg:gap-4">
        <div className="flex flex-col row-start-1 lg:min-w-full lg:h-128 lg:pr-24">
          {images.length >= 1 ? (
            <Image
              cloudName={process.env.CLOUDINARY_CLOUD_NAME}
              publicId={images[0].image[0].public_id}
              alt={images[0].image[0].context?.custom?.alt}
              loading="lazy"
              className="object-cover object-center z-0 lg:min-h-full"
              secure
              dpr="auto"
              crop="scale"
              responsive
              responsiveUseBreakpoints="true"
            >
              <Transformation fetchFormat="webp" quality="auto" />
            </Image>
          ) : (
            // Image is missing. Fallback to logo
            <Image
              cloudName={process.env.CLOUDINARY_CLOUD_NAME}
              publicId="Logo/GH-stempel_RGB_wudxhq"
              alt="Gartnerhallen logo"
              loading="lazy"
              className="object-cover object-center z-0"
              secure
              dpr="auto"
            >
              <Transformation crop="fill" width="574" height="486" fetchFormat="webp" quality="auto" />
            </Image>
          )}
        </div>
        <div className="row-span-2">
          {dataProducerGroups.map((item) => (
            <ProducerLine
              key={item.index}
              heading={item.title}
              ingress={item.text}
              mainPath={producerPath}
              slug={item.slug}
              className="-my-10"
            />
          ))}
        </div>
        <div className="flex flex-col lg:flex-wrap lg:content-end row-start-4 lg:row-start-2 w-full lg:h-96 lg:pr-24">
          {images.length >= 2 ? (
            <Image
              cloudName={process.env.CLOUDINARY_CLOUD_NAME}
              publicId={images[1].image[0].public_id}
              alt={images[1].image[0].context?.custom?.alt}
              loading="lazy"
              className="object-cover object-center z-0 lg:min-h-full lg:w-1/2"
              secure
              dpr="auto"
              crop="scale"
              responsive
              responsiveUseBreakpoints="true"
            >
              <Transformation fetchFormat="webp" quality="auto" />
            </Image>
          ) : (
            // Image is missing. Fallback to logo
            <Image
              cloudName={process.env.CLOUDINARY_CLOUD_NAME}
              publicId="Logo/GH-stempel_RGB_wudxhq"
              alt="Gartnerhallen logo"
              loading="lazy"
              className="object-cover object-center z-0"
              secure
              dpr="auto"
            >
              <Transformation crop="fill" width="574" height="486" fetchFormat="webp" quality="auto" />
            </Image>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProducerSection
