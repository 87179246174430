import { graphql } from "gatsby"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import SEO, { getSeoProps } from "../components/Common/SEO"
// import ProductGroupsSection from "../components/Sections/ProductGroupsSection"
import ProducerSection from "../components/Sections/ProducerSection"
import ProductCardSection from "../components/Sections/ProductCardSection"
import HeroProducerHeadline from "../components/Hero/HeroProducerHeadline"

import getBannerProps from "../components/Hero/getHeroBannerProps"
// import { Heading2 } from "../components/Styling/Headings"
// import { Ingress } from "../components/Styling/Body"

// import getMainPath from "../components/Common/MainPaths"

/**
 * @name ProduktGrupper
 * @description
 * @param param0
 */

const ProduktGruppe = ({ location, data }) => {
  // const mainPath = getMainPath("productgroup")

  const { contentfulGeneric: content } = data

  // Parse the content and create the props needed by SEO component.
  const helmetProps = getSeoProps(content, location, "product")

  // Parse the content, and get the needed banner props
  const heroBannerProps = getBannerProps(content)

  return (
    <>
      <SEO {...helmetProps} />

      <HeroProducerHeadline {...heroBannerProps} />

      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8 mt-8 lg:mt-0">
        {content.body?.childMdx?.body ? (
          // Generic artikkel for produktgruppeside finnse i Contenful
          <MDXRenderer>{content.body.childMdx.body}</MDXRenderer>
        ) : (
          <div></div>
        )}

        <ProductCardSection />
        <ProducerSection />
      </div>
    </>
  )
}

export const productGroupQuery = graphql`
  {
    allContentfulProductGroup(filter: { node_locale: { eq: "nb-NO" } }) {
      nodes {
        id
        contentful_id
        title
        subtitle
        slug
        node_locale
        heroImage {
          id
          public_id
          url
          secure_url
          width
          height
          format
        }
        createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
        updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
        ingress {
          childMdx {
            body
            timeToRead
          }
        }
        body {
          childMdx {
            timeToRead
            body
          }
        }
      }
    }
    contentfulGeneric(slug: { eq: "produktgrupper" }, node_locale: { eq: "nb-NO" }) {
      ingress {
        ingress
        childMdx {
          timeToRead
        }
      }
      body {
        childMdx {
          timeToRead
          body
        }
      }
      author {
        name
        firstName
        lastName
      }
      heroImage {
        id
        public_id
        context {
          custom {
            Credits
            alt
          }
        }
        url
        secure_url
        width
        height
        format
      }
      node_locale
      title
      metadataDescription
      slug
      createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
      updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
    }
  }
`

export default ProduktGruppe
