import { Link } from "gatsby-plugin-react-i18next"
import { TypographyIngress } from "../Styling/Body"
import { HeroSubtitle } from "../Styling/Titles"
import Product from "../Graphics/Product"

const ProductGroupCard = ({ item, mainPath, index }) => {
  const translateAmount = index === 3 || index === 4 ? "lg:translate-x-1/2" : ""

  const title = item.title.toString() || "Title missing"
  return (
    <>
      <div className={item.color + " lg:transform " + translateAmount}>
        <div className="flex justify-center hover:underline">
          <Link className="my-12" to={"/nb" + mainPath + "/" + item.slug}>
            <HeroSubtitle>{title}</HeroSubtitle>
          </Link>
        </div>
        <div className="h-96 mx-auto px-3 overflow-clip overflow-hidden max-w-xs sm:max-w-md">
          <p className={TypographyIngress}>{item.text}</p>
        </div>
        <Product image={item.image} />
        <div className="flex justify-center object-bottom mt-4 z-20 relative">
          <Link to={"/nb" + mainPath + "/" + item.slug}>
            <button
              type="button"
              className="bg-transparent text-tomat-500 hover:bg-tomat-500 hover:text-white font-semibold hover:underline py-2 px-4 border border-tomat-500 hover:border-transparent rounded-full"
              id="user-menu"
              aria-haspopup="true"
            >
              <div className="hidden lg:inline-block">Les mer om {item.title.toLowerCase()}</div>
              <div className="lg:hidden mx-4">Finn ut mer</div>
            </button>
          </Link>
        </div>
        <div className="h-8"></div>
      </div>
    </>
  )
}

export default ProductGroupCard
