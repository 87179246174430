import getMainPath from "../Common/MainPaths"
import { graphql, useStaticQuery } from "gatsby"
import ProductGroupCard from "../Cards/ProductGroupCard"
import { getCloudinaryImage } from "../Common/Cloudinary"
import getProductColor from "../Styling/getProductColor"

const ProductCardSection = () => {
  const mainPath = getMainPath("productgroup")

  // Hent alle produktgrupper fra Contentful
  const data = useStaticQuery(graphql`
    {
      allContentfulProductGroup(filter: { node_locale: { eq: "nb-NO" } }) {
        nodes {
          backgroundColor
          id
          title
          slug
          ingress {
            ingress
          }
          heroImage {
            id
            public_id
            url
            secure_url
            width
            height
            format
          }
        }
      }
    }
  `)

  // Bygg opp liste over produktgrupper for bruk av denne komponenten.
  let counter = 0
  const dataProductGroups = data.allContentfulProductGroup.nodes.map((item) => ({
    index: counter++,
    slug: item.slug,
    title: item.title || "Tittel mangler i contentful",
    color: getProductColor(item.backgroundColor),
    image: getCloudinaryImage(item.heroImage[0]),
    text: item.ingress.ingress || "Text mangler i Contentful",
  }))

  return (
    <div>
      <div className="relative lg:grid lg:grid-cols-3 lg:gap-4">
        {dataProductGroups.map((data, index) => (
          <>
            <ProductGroupCard key={data.index} item={data} mainPath={mainPath} index={index} />
          </>
        ))}
      </div>
      <div className="bg-squash-500 bg-rosmarin-500 bg-himmel-500 bg-baer-500 bg-tomat-500 bg-bgokologisk-500 bg-bggronsaker-500 bg-bgpoteter-500 bg-bgfrukt-500 bg-bgbaer-500 hidden"></div>
    </div>
  )
}

export default ProductCardSection
